import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if @isPrintView}}\n  <img src={{this.imgsrc}} width='100%' height='100%' />\n{{else}}\n  <div class='chart' style='width: 100%;height:{{this.height}}; '></div>\n{{/if}}", {"contents":"{{#if @isPrintView}}\n  <img src={{this.imgsrc}} width='100%' height='100%' />\n{{else}}\n  <div class='chart' style='width: 100%;height:{{this.height}}; '></div>\n{{/if}}","moduleName":"bi/components/report/gauge-visual.hbs","parseOptions":{"srcName":"bi/components/report/gauge-visual.hbs"}});
import Component from '@ember/component';
import { later } from '@ember/runloop';
import * as echarts from 'echarts';

import textformatter from '../../utils/textformatter';

export default Component.extend({
  height: '100%',
  getColor() {
    let colorRgb = this.visual.displayProperties.fontColor;
    return colorRgb;
  },
  getOptions(visual, min, max, value) {
    this.set('parentHeight', this.parentView.$().height());

    let parentHeight = this.parentView.$().height();

    let gaugeWidth = 18;
    let pointerSize = 16;
    let labelFontSize = 20;
    let labelFontDistance = 30;
    let splits = 10;

    if (parentHeight < 400) {
      pointerSize = 8;
      gaugeWidth = 10;
      labelFontSize = 10;
      labelFontDistance = 15;
      splits = 5;
    }

    let options = {
      series: [
        {
          type: 'gauge',
          min: min,
          max: max,
          splitNumber: splits,
          itemStyle: {
            shadowBlur: 2,
            shadowOffsetX: 1,
            shadowOffsetY: 1,
          },
          pointer: {
            itemStyle: {
              color: 'auto',
            },
          },
          axisLine: {
            lineStyle: {
              width: gaugeWidth,
            },
          },
          axisTick: {
            splitNumber: 2,
            lineStyle: {
              width: 2,
              color: '#999',
            },
          },
          splitLine: {
            length: 12,
            lineStyle: {
              width: 3,
              color: '#999',
            },
          },
          axisLabel: {
            distance: labelFontDistance,
            color: '#999',
            fontSize: labelFontSize,
            formatter: function (val) {
              return textformatter.format(val, visual.values[0].format);
            },
          },
          title: {
            show: false,
          },
          detail: {
            width: '60%',
            lineHeight: 40,
            height: 40,
            offsetCenter: [0, '90%'],
            fontSize: labelFontSize,
            color: '#777',
            formatter: function (val) {
              return textformatter.format(val, visual.values[0].format);
            },
          },
          data: [
            {
              value: value,
            },
          ],
        },
      ],
    };

    return options;
  },
  drawChart() {
    let visual = this.visual;

    if (this.imgsrc) {
      return;
    }

    let data = this.data;
    if (!data || !visual) {
      return;
    }

    let value = '-';
    if (data.results && data.results.length > 0) {
      let row = data.results[0];

      if (visual.groups && row.length > visual.groups.length + 1) {
        value = row[visual.groups.length];
      } else {
        value = row[0];
      }
    }

    this.set('value', value);

    let min = visual.displayProperties.min || 0;
    let max = visual.displayProperties.max || 100;

    let options = this.getOptions(visual, min, max, value);

    if (visual.alerts && visual.alerts.length > 0) {
      options.series[0].axisLine.lineStyle.color = [];
      options.series[0].pointer = {
        itemStyle: {
          color: 'auto',
        },
      };

      let alertMap = {};

      visual.alerts.forEach((a) => {
        alertMap[a.max] = a.color;

        if (a.min == '') {
          a.min = 0;
        }

        if (!alertMap[a.min]) {
          alertMap[a.min] = '#999';
        }
      });

      Object.keys(alertMap)
        .sort()
        .forEach((k) => {
          let threshold = k / (max - min);
          options.series[0].axisLine.lineStyle.color.pushObject([
            threshold,
            alertMap[k],
          ]);
        });

      if (max == 1) {
        options.series[0].axisLine.lineStyle.color.pushObject([1, '#999']);
      }
    } else {
      // debugger;
      options.series[0].pointer.itemStyle.color = this.getColor();
      options.series[0].progress = {
        show: true,
        color: this.getColor(),
        itemStyle: {
          color: this.getColor(),
        },
      };
    }
    // debugger;
    if (this.isPrintView) {
      let self = this;
      let chartDiv = this.$().find('img')[0];
      let myChart = echarts.init(chartDiv);
      myChart.setOption(options);

      later(function () {
        myChart.resize();
        var data = myChart.getConnectedDataURL({
          pixelRatio: 2,
          excludeComponents: ['toolbox'],
        });
        self.set('imgsrc', data);
      }, 500);
    } else {
      let chartDiv = this.$().find('div')[0];
      let myChart = echarts.init(chartDiv);

      later(function () {
        // use configuration item and data specified to show chart
        myChart.setOption(options);
        myChart.resize();
      }, 500);
    }
  },
  redraw() {
    this.set('show', false);
    let self = this;

    setTimeout(function () {
      self.set('show', true);
      self.drawChart();
    }, 100);
  },
  willDestroyElement() {
    this._super(...arguments);

    let parentContainer = this.parentContainer;
    if (parentContainer) {
      this.parentContainer.element.removeEventListener(
        'resizestop',
        this.onResizeStop
      );
    }
  },
  onResizeStop(event, element) {
    this.drawChart(element.size.height);
  },
  didRender() {
    this._super(...arguments);

    this.drawChart();
    let parentContainer = this.parentContainer;

    if (parentContainer) {
      this.parentContainer.element.addEventListener(
        'resizestop',
        this.onResizeStop
      );
    }
  },
});
