import { helper as buildHelper } from '@ember/component/helper';

export function contains(params) {
  if (params.length < 2) {
    return false;
  }

  if (!params[0] || !params[1]) {
    return false;
  }

  return params[0].toLowerCase().indexOf(params[1].toLowerCase()) > -1;
}

export default buildHelper(contains);
