let options = [
  {
    text: 'Default',
    key: 'default',
  },
  {
    text: 'hh:mm:ss',
    key: 'time_to_hour_min_sec',
    moment: 'hh:mm:ss',
  },
  {
    text: 'hh:mm',
    key: 'time_to_hour_min',
    moment: 'hh:mm',
  },
  {
    text: 'mm.00',
    key: 'time_to_minute',
    moment: 'mm',
  },
  {
    text: 'mm:ss',
    key: 'time_to_minute_second',
    moment: 'mm:ss',
  },
  {
    text: 'ss',
    key: 'time_to_second',
    moment: 'ss',
  },
  {
    text: 'nnn,nnn,nnn',
    key: 'commas',
  },
  {
    text: 'nnn,nnn,nnn.nn',
    key: 'commas_with_decimal',
  },
  {
    text: 'nn.nn',
    key: 'round_2_decimals',
  },
  {
    text: 'nn.n',
    key: 'round_1_decimals',
  },
  {
    text: 'nn',
    key: 'round_0_decimals',
  },
  {
    text: 'nn.nn%',
    key: 'percent_2_decimals',
  },
  {
    text: 'nn.n%',
    key: 'percent_1_decimals',
  },
  {
    text: 'nn%',
    key: 'percent_0_decimals',
  },
  {
    text: 'Day Of Week',
    key: 'dow',
    moment: 'dddd',
  },
  {
    text: 'Day of Month',
    key: 'dom',
    moment: 'DD',
  },
  {
    text: 'YYYY-MM-DD',
    key: 'yyyymmdd',
    moment: 'YYYY-MM-DD',
  },
  {
    text: 'YYYY/MM/DD HH:mm',
    key: 'yyyymmddhhmm',
    moment: 'YYYY/MM/DD HH:mm',
  },
  {
    text: 'YYYY-MM-DD HH:mm',
    key: 'yyyymmddhhmm2',
    moment: 'YYYY-MM-DD HH:mm',
  },
  {
    text: 'YYYY/MM/DD HH:mm:ss',
    key: 'yyyymmddhhmmss',
    moment: 'YYYY/MM/DD HH:mm:ss',
  },
  {
    text: 'YYYY-MM-DD HH:mm:ss',
    key: 'yyyymmddhhmmss2',
    moment: 'YYYY-MM-DD HH:mm:ss',
    // },
    // {
    //     text: "Time Since",
    //     key: "time_since",
  },
];

export default options;
