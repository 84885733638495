import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{!-- <SelectList @class=\"form-control\" @content={{datasets}} @optionValuePath=\"id\" @optionLabelPath=\"name\" @value={{selectedDataSet}} @action={{action (mut selectedDataSet)}} /> --}}\n\n<PowerSelect\n  @class='field-extra-options'\n  @triggerId='dataset-selector'\n  @options={{this.datasets}}\n  @selected={{this.selectedDataSet}}\n  @searchField='name'\n  @searchEnabled={{true}}\n  @onChange={{action (mut this.selectedDataSet)}}\n  as |ds|\n>\n  {{ds.name}}\n</PowerSelect>", {"contents":"{{!-- <SelectList @class=\"form-control\" @content={{datasets}} @optionValuePath=\"id\" @optionLabelPath=\"name\" @value={{selectedDataSet}} @action={{action (mut selectedDataSet)}} /> --}}\n\n<PowerSelect\n  @class='field-extra-options'\n  @triggerId='dataset-selector'\n  @options={{this.datasets}}\n  @selected={{this.selectedDataSet}}\n  @searchField='name'\n  @searchEnabled={{true}}\n  @onChange={{action (mut this.selectedDataSet)}}\n  as |ds|\n>\n  {{ds.name}}\n</PowerSelect>","moduleName":"bi/components/dataset-selector.hbs","parseOptions":{"srcName":"bi/components/dataset-selector.hbs"}});
import { observer } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@ember/component';

export default Component.extend({
  configService: service(),
  datasets: [],
  selectedDataSet: null,
  init() {
    this._super(...arguments);
    this.set(
      'selectedDataSet',
      this.configService.dataSets.findBy(
        'id',
        this.configService.get('currentdatasourceId')
      )
    );

    this.datasets.setObjects(this.configService.dataSets);

    this.selectedDataSet;
  },
  // configServiceValueChanged: observer('configService.currentdatasourceId', function() {
  //     let currentDataSet = this.configService.get("currentdatasourceId");

  //     if(currentDataSet){
  //         this.set("selectedDataSet", currentDataSet);
  //     }
  // }),
  configDataSetsChanged: observer('configService.dataSets', function () {
    this.datasets.setObjects(this.configService.dataSets);
    this.set(
      'selectedDataSet',
      this.configService.dataSets.findBy(
        'id',
        this.configService.get('currentdatasourceId')
      )
    );
  }),

  selectedValueChanged: observer('selectedDataSet', function () {
    this.configService.set('currentdatasourceId', this.selectedDataSet.id);
  }),
});
