import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<label>{{@config.tableDefinition.displayname}} Column</label>\n<PowerSelect\n  @class='field-extra-options'\n  @triggerId='config-join-column'\n  @searchEnabled={{true}}\n  @options={{this.baseColumns}}\n  @selected={{@join.left}}\n  @onChange={{action (mut @join.left)}}\n  as |col|\n>\n  {{col}}\n</PowerSelect>\n<label>{{@relation.tableDefinition.displayname}} Column</label>\n{{#if this.relationColumns}}\n  <PowerSelect\n    @class='field-extra-options'\n    @triggerId='relation-join-column'\n    @searchEnabled={{true}}\n    @options={{this.relationColumns}}\n    @selected={{@join.right}}\n    @onChange={{action (mut @join.right)}}\n    as |col|\n  >\n    {{col}}\n  </PowerSelect>\n{{/if}}", {"contents":"<label>{{@config.tableDefinition.displayname}} Column</label>\n<PowerSelect\n  @class='field-extra-options'\n  @triggerId='config-join-column'\n  @searchEnabled={{true}}\n  @options={{this.baseColumns}}\n  @selected={{@join.left}}\n  @onChange={{action (mut @join.left)}}\n  as |col|\n>\n  {{col}}\n</PowerSelect>\n<label>{{@relation.tableDefinition.displayname}} Column</label>\n{{#if this.relationColumns}}\n  <PowerSelect\n    @class='field-extra-options'\n    @triggerId='relation-join-column'\n    @searchEnabled={{true}}\n    @options={{this.relationColumns}}\n    @selected={{@join.right}}\n    @onChange={{action (mut @join.right)}}\n    as |col|\n  >\n    {{col}}\n  </PowerSelect>\n{{/if}}","moduleName":"bi/components/data-source-table-join-on.hbs","parseOptions":{"srcName":"bi/components/data-source-table-join-on.hbs"}});
import Component from '@ember/component';
import { observer, set } from '@ember/object';

export default Component.extend({
  baseColumns: [],
  relationColumns: [],

  relationChanged: observer(
    'relation',
    'relation.tableDefinition',
    function () {
      this.loadColumnDetails();
      try {
        let newList = [];

        this.relation.tableDefinition.columns.forEach((col) => {
          newList.pushObject(col.name);
        });

        this.relationColumns.setObjects(newList);
      } catch (ex) {
        //noop
      }

      for (let x = 0; x < this.relationColumns.length; x++) {
        if (this.relationColumns[x] === this.join.right) {
          return;
        }
      }

      set(this.join, 'right', '');
    }
  ),
  configChanged: observer('config', 'config.tableDefinition', function () {
    this.loadColumnDetails();
    this.baseColumns.clear();

    this.config.tableDefinition.columns.forEach((col) => {
      this.baseColumns.pushObject(col.name);
    });

    for (let x = 0; x < this.baseColumns.length; x++) {
      if (this.baseColumns[x] === this.join.left) {
        return;
      }
    }

    set(this.join, 'left', '');
  }),

  loadColumnDetails() {
    if (
      this.config.tableDefinition &&
      this.config.tableDefinition.columns &&
      this.baseColumns.length === 0
    ) {
      this.config.tableDefinition.columns.forEach((col) => {
        this.baseColumns.pushObject(col.name);
      });
    }

    try {
      let newList = [];

      if (
        this.relation.tableDefinition &&
        this.relation.tableDefinition.columns
      ) {
        this.relation.tableDefinition.columns.forEach((col) => {
          newList.pushObject(col.name);
        });
      }

      this.relationColumns.setObjects(newList);
    } catch (ex) {
      //noop
    }
  },
  init() {
    this._super(...arguments);
    try {
      //Reset the base columns in case the CTE was changed
      if (this.config.tableDefinition) {
        this.baseColumns.clear();
      }
      //Reset the relation columns in case the CTE was changed
      if (this.relation.tableDefinition) {
        this.relationColumns.clear();
      }
      this.loadColumnDetails();
      // Update the flag to indicate that initialization has occurred
    } catch (ex) {}
  },
});
