import { Promise } from 'rsvp';

import Evented from '@ember/object/evented';
import Service, { inject as service } from '@ember/service';

import uuidv4 from '../utils/uuid';

//const SERVER = "http://d22huhlq55w9kn.cloudfront.net";
const SERVER = ''; // "http://localhost:8080";

export default Service.extend(Evented, {
  router: service(),
  ajax: service(),
  headlessService: service(),
  configService: service(),
  instanceId: uuidv4(),
  csrfToken: '',
  getServer() {
    return SERVER + '/server';
  },

  _loginRedirect() {
    if (this.headlessService.isHeadless()) {
      return;
    }

    let currentUrl = this.router.currentURL;

    if (window.location.hostname === 'localhost') {
      window.location.replace(
        'https://localhost:8443/server/login?state=' + currentUrl
      ); // SERVER + "/server/login");
    } else {
      window.location.replace('/server/login?state=' + currentUrl);
    }
  },
  logError(message) {
    this.postResource('/logger/error', {
      message: message,
    });
  },

  deleteResource(url) {
    let self = this;
    try {
      return new Promise(function (resolve, reject) {
        //resolve({});
        $.ajax({
          url: self.getServer(url) + url,
          type: 'DELETE',
          contentType: 'application/json',
          //json object to sent to the authentication url
          beforeSend: function (xhr) {
            xhr.withCredentials = true;
          },
        })
          .then(function (data) {
            resolve(data);
          })
          .catch(function (err) {
            console.error(err);
            console.error(typeof err);
            if (
              err.status === 401 ||
              err.message === 'Ajax authorization failed'
            ) {
              console.log('REDIRECTING');
              self._loginRedirect(self);
            }
            reject(err);
          });
      });
    } catch (e) {
      console.error(e);
    }
  },

  putResource(url, data) {
    let self = this;
    try {
      let dataString = JSON.stringify(data);

      return new Promise(function (resolve, reject) {
        $.ajax({
          url: self.getServer(url) + url,
          type: 'PUT',
          data: dataString,
          contentType: 'application/json',
          beforeSend: function (xhr) {
            xhr.withCredentials = true;
          },
        })
          .then(function (data) {
            resolve(data);
          })
          .catch(function (err) {
            console.error(err);
            console.error(typeof err);
            if (
              err.status === 401 ||
              err.message === 'Ajax authorization failed'
            ) {
              console.log('REDIRECTING');
              self._loginRedirect(self);
            }
            reject(err);
          });
      });
    } catch (e) {
      console.error(e);
    }
  },

  getResource(url) {
    let self = this;
    try {
      return new Promise(function (resolve, reject) {
        let request = {
          url: self.getServer(url) + url,
          type: 'GET',
          contentType: 'application/json',
          //json object to sent to the authentication url
          beforeSend: function (xhr) {
            xhr.withCredentials = true;
          },
        };

        request.headers = {
          'x-org': self.get('configService').get('currentdatasourceId'),
          'x-orgname': self.get('configService').get('currentOrgName'),
          'x-bi-instance': self.instanceId,
        };

        if (self.headlessKey) {
          request.headers['x-key'] = self.headlessKey;
        }

        $.ajax(request)
          .then(function (data, status, response) {
            if (response.getResponseHeader('X-CSRF-Token')) {
              self.set('csrfToken', response.getResponseHeader('X-CSRF-Token'));
            }

            resolve(data);
          })
          .catch(function (err) {
            console.error(err);
            console.error(typeof err);
            if (
              err.status === 401 ||
              err.message === 'Ajax authorization failed'
            ) {
              console.log('REDIRECTING after a get request');
              self._loginRedirect(self);
            }
            reject(err);
          });
      });
    } catch (e) {
      console.error(e);
    }
  },
  postResource(url, data) {
    let self = this;
    try {
      let requestPromise = new Promise(function (resolve, reject) {
        let dataString = JSON.stringify(data);

        let request = {
          url: self.getServer(url) + url,
          type: 'POST',
          contentType: 'application/json',
          //json object to sent to the authentication url
          data: dataString,
          beforeSend: function (xhr) {
            xhr.withCredentials = true;
          },
        };

        request.headers = {
          'x-org': self.get('configService').get('currentdatasourceId'),
          'x-orgname': self.get('configService').get('currentOrgName'),
          'X-CSRF-Token': self.csrfToken,
          'x-bi-instance': self.instanceId,
        };

        // debugger;
        if (self.headlessKey) {
          request.headers['x-key'] = self.headlessKey;
        }

        $.ajax(request)
          .always(function (response) {
            resolve(response);
          })
          .catch(function (err) {
            if (err.status === 401) {
              self._loginRedirect(self);
              resolve();
            } else {
              console.error('Status Code:', err.status);
              console.error('Status Text:', err.statusText);
              console.error('Response Text:', err.responseText);
              reject(err);
            }
          });
      });

      return requestPromise;
    } catch (e) {
      console.error(e);
    }
  },
  postWithoutLoginHandler(url, data) {
    let request = {
      url: this.getServer(url) + url,
      type: 'POST',
      contentType: 'application/json',
      //json object to sent to the authentication url
      data: JSON.stringify(data),
      beforeSend: function (xhr) {
        xhr.withCredentials = true;
      },
    };

    request.headers = {
      'x-org': this.configService.get('currentdatasourceId'),
      'x-orgname': self.get('configService').get('currentOrgName'),
      'X-CSRF-Token': self.csrfToken,
      'x-bi-instance': self.instanceId,
    };
    return $.ajax(request);
  },
});
