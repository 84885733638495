import Service, { inject as service } from '@ember/service';

const DatabaseName = 'requests';

export default Service.extend({
  requestService: service(),
  init() {
    this._super(...arguments);

    if (!window.indexedDB) {
      console.log("Your browser doesn't support a stable version of IndexedDB");
      return;
    }

    //TEMPORARY DISABLE DB CACHE
    return;

    // let self = this;
    // let request = window.indexedDB.open('PIBI', 1);
    //
    // request.onerror = function (event) {
    //   console.log(event);
    // };
    // request.onsuccess = function (event) {
    //   self.set('db', event.target.result);
    //
    //   //clear out expired items
    //   let objectStore = self.db
    //     .transaction([DatabaseName], 'readwrite')
    //     .objectStore(DatabaseName);
    //
    //   objectStore.openCursor().onsuccess = function (event) {
    //     var cursor = event.target.result;
    //     if (cursor) {
    //       if (cursor.value.expires <= new moment().unix()) {
    //         objectStore.delete(cursor.key);
    //       }
    //       cursor.continue();
    //     }
    //   };
    // };
    //
    // request.onupgradeneeded = function (evt) {
    //   var dbobject = evt.target.result;
    //   dbobject.createObjectStore(DatabaseName, { keyPath: 'key' });
    // };
  },
  hash(s) {
    if (!s) {
      return '';
    }
    return s.split('').reduce(function (a, b) {
      a = (a << 5) - a + b.charCodeAt(0);
      return a & a;
    }, 0);
  },
  start() {},

  cacheResults(url, data, results) {
    this.cacheResultsWithExpiration(
      new moment().add(1, 'h').unix(),
      url,
      data,
      results
    );
  },

  cacheResultsWithExpiration(expiration, url, data, results) {
    if (this.db) {
      let requestKey = url + this.hash(JSON.stringify(data));

      let transaction = this.db.transaction([DatabaseName], 'readwrite');
      let objectStore = transaction.objectStore(DatabaseName);
      objectStore.add({
        key: requestKey,
        expires: expiration,
        result: results,
      });
    }
  },

  clearItem(url, data) {
    if (!this.db) {
      return Promise.resolve();
    }

    return new Promise((resolve) => {
      let requestKey = url + this.hash(JSON.stringify(data));
      let request = this.db
        .transaction([DatabaseName], 'readwrite')
        .objectStore(DatabaseName)
        .delete(requestKey);

      request.onsuccess = function (event) {
        resolve();
      };

      request.onerror = function (event) {
        resolve();
      };
    });
  },

  getResource(url) {
    return this.getResourceWithExpiration(
      url,
      new moment().add(1, 'h').unix(),
      false
    );
  },
  getResourceWithExpiration(url, expiration, forceUpdate) {
    if (!this.db) {
      return this.requestService.getResource(url);
    }

    return new Promise((resolve, reject) => {
      let requestKey = url;

      let transaction = this.db.transaction([DatabaseName]);
      var objectStore = transaction.objectStore(DatabaseName);
      var request = objectStore.get(requestKey);

      request.onsuccess = (event) => {
        if (
          forceUpdate === true ||
          !request.result ||
          !request.result.expires ||
          request.result.expires < new moment().unix()
        ) {
          this.requestService
            .getResource(url)
            .then((res) => {
              let transaction = this.db.transaction(
                [DatabaseName],
                'readwrite'
              );
              let objectStore = transaction.objectStore(DatabaseName);
              objectStore.add({
                key: requestKey,
                expires: expiration,
                result: res,
              });

              resolve(res);
            })
            .catch((ex) => {
              reject(ex);
            });

          return;
        }

        resolve(request.result.result);
      };

      request.onerror = (event) => {
        this.requestService
          .getResource(url)
          .then((res) => {
            resolve(res);
          })
          .catch((ex) => {
            reject(ex);
          });
      };
    });
  },

  postResource(url, data) {
    if (!this.db) {
      return this.requestService.postResource(url, data);
    }

    return new Promise((resolve, reject) => {
      let requestKey = url + this.hash(JSON.stringify(data));

      var transaction = this.db.transaction([DatabaseName]);
      var objectStore = transaction.objectStore(DatabaseName);
      var request = objectStore.get(requestKey);

      request.onsuccess = (event) => {
        if (
          !request.result ||
          !request.result.expires ||
          request.result.expires < new moment().unix()
        ) {
          this.requestService
            .postResource(url, data)
            .then((res) => {
              resolve(res);
            })
            .catch((ex) => {
              reject(ex);
            });

          return;
        }

        resolve(request.result.result);
      };

      request.onerror = (event) => {
        this.requestService
          .postResource(url, data)
          .then((res) => {
            resolve(res);
          })
          .catch((ex) => {
            reject(ex);
          });
      };
    });
  },
});
