import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<ul class='rb-list number-filter-list'>\n  <li>\n    <RadioButton\n      @value='true'\n      @groupValue={{this.filterValue}}\n      @changed={{action 'selectedValueChanged'}}\n    >\n      <label>True</label>\n    </RadioButton>\n  </li>\n  <li>\n    <RadioButton\n      @value='false'\n      @groupValue={{this.filterValue}}\n      @changed={{action 'selectedValueChanged'}}\n    >\n      <label>False</label>\n    </RadioButton>\n  </li>\n  <li>\n    <RadioButton\n      @value='any'\n      @groupValue={{this.filterValue}}\n      @changed={{action 'selectedValueChanged'}}\n    >\n      <label>Any</label>\n    </RadioButton>\n  </li>\n</ul>", {"contents":"<ul class='rb-list number-filter-list'>\n  <li>\n    <RadioButton\n      @value='true'\n      @groupValue={{this.filterValue}}\n      @changed={{action 'selectedValueChanged'}}\n    >\n      <label>True</label>\n    </RadioButton>\n  </li>\n  <li>\n    <RadioButton\n      @value='false'\n      @groupValue={{this.filterValue}}\n      @changed={{action 'selectedValueChanged'}}\n    >\n      <label>False</label>\n    </RadioButton>\n  </li>\n  <li>\n    <RadioButton\n      @value='any'\n      @groupValue={{this.filterValue}}\n      @changed={{action 'selectedValueChanged'}}\n    >\n      <label>Any</label>\n    </RadioButton>\n  </li>\n</ul>","moduleName":"bi/components/filter/filter-boolean-editor.hbs","parseOptions":{"srcName":"bi/components/filter/filter-boolean-editor.hbs"}});
import Component from '@ember/component';
import { observer, set } from '@ember/object';

export default Component.extend({
  isTrue: false,

  didReceiveAttrs() {
    this._super(...arguments);
    let filter = this.filter;

    if (!filter) {
      this.calculateFilter();
      return;
    }

    this.set('filterValue', 'any');

    if (filter.values && filter.values.length > 0) {
      if (filter.values[0] === true) {
        this.set('filterValue', 'true');
      } else if (filter.values[0] === false) {
        this.set('filterValue', 'false');
      }
    }

    if (!filter.operator) {
      this.calculateFilter();
      return;
    }
  },

  calculateFilter() {
    let filter = this.filter;

    let value = this.filterValue;

    if (value == 'true') {
      value = true;
    } else if (value == 'false') {
      value = false;
    }

    let values = [value];

    set(filter, 'values', values);
    set(filter, 'operator', '=');

    this.set('filter', filter);
  },
  filterValueChanged: observer('filterValue', function () {
    this.calculateFilter();
  }),
  actions: {
    selectedValueChanged(val) {
      this.set('filterValue', val);
      this.calculateFilter();
    },
  },
});
