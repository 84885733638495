import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AceEditor\n  ...attributes\n  @value={{@value}}\n  @update={{@valueChanged}}\n  @ready={{@ready}}\n  @wrapMethod={{this.wrapMethod}}\n  @options={{hash\n    mode='ace/mode/sql'\n    theme='ace/theme/github'\n    minLines=this.minLines\n    maxLines=this.maxLines\n    enableLiveAutocompletion=this.enableLiveAutocompletion\n    enableBasicAutocompletion=this.enableBasicAutocompletion\n    keyboardHandler=@keyboardHandler\n  }}\n/>", {"contents":"<AceEditor\n  ...attributes\n  @value={{@value}}\n  @update={{@valueChanged}}\n  @ready={{@ready}}\n  @wrapMethod={{this.wrapMethod}}\n  @options={{hash\n    mode='ace/mode/sql'\n    theme='ace/theme/github'\n    minLines=this.minLines\n    maxLines=this.maxLines\n    enableLiveAutocompletion=this.enableLiveAutocompletion\n    enableBasicAutocompletion=this.enableBasicAutocompletion\n    keyboardHandler=@keyboardHandler\n  }}\n/>","moduleName":"bi/components/editor/raw-sql.hbs","parseOptions":{"srcName":"bi/components/editor/raw-sql.hbs"}});
import Component from '@glimmer/component';

import 'ace-builds/src-noconflict/ace';
import 'ace-builds/src-noconflict/theme-github';
import 'ace-builds/src-noconflict/mode-sql';
import langTools from 'ace-builds/src-noconflict/ext-language_tools';

import autocomplete from '../../utils/autocomplete';

const completer = {
  getCompletions: (editor, session, pos, prefix, callback) => {
    if (prefix.length === 0) {
      callback(null, []);
      return;
    }

    let suggests = [];

    autocomplete.forEach((op) => {
      let prefixIndex = op.type.toLowerCase().indexOf(prefix.toLowerCase());

      if (prefixIndex === 0) {
        let score = prefix.length - prefixIndex;
        suggests.push({
          value: op.type,
          snippet: op.value,
          score: score,
        });
      }
    });

    callback(null, suggests);
  },
};

langTools.addCompleter(completer);

export default class RawSql extends Component {
  get minLines() {
    return this.args.minLines || this.args.lines;
  }

  get maxLines() {
    return this.args.maxLines || this.args.lines;
  }

  get enableLiveAutocompletion() {
    return this.args.enableLiveAutocompletion ?? true;
  }

  get enableBasicAutocompletion() {
    return this.args.enableBasicAutocompletion ?? true;
  }

  get wrapMethod() {
    return this.args.wrapMethod || 'code';
  }
}
