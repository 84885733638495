import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class='print-view'>\n  <h1 class='h2 report-header'>\n    {{this.report.name}}\n\n    <div class='text-danger'>\n      {{this.error}}\n    </div>\n  </h1>\n  <Filter::FilterDisplay @filterDisplay={{this.filterDisplay}} />\n  {{#each this.report.visuals as |visual index|}}\n    <div class='{{if (not-eq index 0) \"visual-break-before\"}}'>\n      <h3>{{visual.name}}</h3>\n      {{#if (eq visual.visualtype 'table')}}\n        <Report::VisualView\n          @visual={{visual}}\n          @dataSources={{this.dataSources}}\n          @isPrintView={{true}}\n        />\n      {{else}}\n        <Report::VisualView\n          @visual={{visual}}\n          @dataSources={{this.dataSources}}\n          style='height:500px'\n          @isPrintView={{true}}\n        />\n      {{/if}}\n    </div>\n  {{/each}}\n</div>", {"contents":"<div class='print-view'>\n  <h1 class='h2 report-header'>\n    {{this.report.name}}\n\n    <div class='text-danger'>\n      {{this.error}}\n    </div>\n  </h1>\n  <Filter::FilterDisplay @filterDisplay={{this.filterDisplay}} />\n  {{#each this.report.visuals as |visual index|}}\n    <div class='{{if (not-eq index 0) \"visual-break-before\"}}'>\n      <h3>{{visual.name}}</h3>\n      {{#if (eq visual.visualtype 'table')}}\n        <Report::VisualView\n          @visual={{visual}}\n          @dataSources={{this.dataSources}}\n          @isPrintView={{true}}\n        />\n      {{else}}\n        <Report::VisualView\n          @visual={{visual}}\n          @dataSources={{this.dataSources}}\n          style='height:500px'\n          @isPrintView={{true}}\n        />\n      {{/if}}\n    </div>\n  {{/each}}\n</div>","moduleName":"bi/components/report-headless-view.hbs","parseOptions":{"srcName":"bi/components/report-headless-view.hbs"}});
import { observer } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
export default Component.extend({
  reportService: service(),
  configService: service(),
  requestService: service(),
  settingsService: service(),
  filterDisplay: [],

  init() {
    this._super(...arguments);
  },
  didReceiveAttrs() {
    this._super(...arguments);
    this.loadReport();
  },

  reportChanged: observer('reportId', function () {
    this.loadReport();
    this.set('edit', false);
  }),
  loadReportFilters(report) {
    if (!report.dataSources || report.dataSources.length === 0) {
      return;
    }

    this.filterDisplay.clear();

    report.dataSources.forEach((ds) => {
      let filterRequest = {
        dataSource: ds,
        timezone: this.settingsService.timezone.trim(),
        reportContext: {
          reportId: report.id,
          visualIndex: 0,
        },
      };
      this.filterDisplay.clear();
      this.requestService
        .postResource('/report/execute/displayablefilters', filterRequest)
        .then((filters) => {
          if (filters && filters.filters) {
            filters.filters.forEach((f) => {
              let columnAndOperator = f.column.split('.').slice(-1)[0];

              let value = f.value;
              this.filterDisplay.pushObject({
                column: columnAndOperator,
                value: value,
              });
            });
          }
        })
        .catch((ex) => {
          if (ex.responseJSON && ex.responseJSON.message) {
            console.log(
              `Unable to retrieve displayable filters - ${ex.responseJSON.message}`
            );
          } else {
            console.log(`Unable to retrieve displayable filters`);
          }
        });
    });
  },
  loadReport() {
    if (this.isDestroyed) {
      return;
    }

    if (this.reportId) {
      this.requestService
        .getResource(`/report/headless/${this.reportId}`)
        .then((returnedReport) => {
          this.requestService.headlessKey = this.reportId;

          if (returnedReport) {
            let report = JSON.parse(JSON.stringify(returnedReport));

            this.loadReportFilters(report);

            report.visuals.sort((a, b) => {
              let sortA = a.location.x;
              let sortB = b.location.x;

              if (a.location.x == b.location.x) {
                sortA = a.location.y;
                sortB = b.location.y;
              }

              return sortA - sortB;
            });

            this.set('report', report);

            this.set('dataSources', report.dataSources);
          }
        })
        .catch((ex) => {
          if (ex.responseJSON.message) {
            this.set(
              'error',
              'Error loading headless report: ' + ex.responseJSON.message
            );
          } else {
            this.set('error', 'Unable to load report');
          }
        });
    }
  },
});
