import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class='container-full'>\n\n  <div class='row'>\n    <div class='col form-inline'>\n      <Input\n        class='form-check-input'\n        @type='checkbox'\n        name='showGrandTotal'\n        id='showGrandTotal'\n        @checked={{this.showGrandTotal}}\n      />\n      <label for='showGrandTotal'>Show Grand Total</label>\n\n    </div>\n  </div>\n\n</div>", {"contents":"<div class='container-full'>\n\n  <div class='row'>\n    <div class='col form-inline'>\n      <Input\n        class='form-check-input'\n        @type='checkbox'\n        name='showGrandTotal'\n        id='showGrandTotal'\n        @checked={{this.showGrandTotal}}\n      />\n      <label for='showGrandTotal'>Show Grand Total</label>\n\n    </div>\n  </div>\n\n</div>","moduleName":"bi/components/editor/table-options.hbs","parseOptions":{"srcName":"bi/components/editor/table-options.hbs"}});
import { observer, set } from '@ember/object';
import Component from '@ember/component';

export default Component.extend({
  didReceiveAttrs() {
    this._super(...arguments);

    let properties = this.visual.displayProperties;

    if (properties.showGrandTotal) {
      this.set('showGrandTotal', properties.showGrandTotal === 'true');
    }
  },
  selectedVisualTypeChanged: observer('showGrandTotal', function () {
    let properties = this.visual.displayProperties;

    let formatVal = this.showGrandTotal;
    set(properties, 'showGrandTotal', `${formatVal}`);
  }),
});
