import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class='container-full'>\n  <div class='row'>\n    <div class='col form-inline'>\n      <label>Font Color</label>\n      <Input @value={{@visual.displayProperties.fontColor}} @type='color' />\n    </div>\n  </div>\n</div>", {"contents":"<div class='container-full'>\n  <div class='row'>\n    <div class='col form-inline'>\n      <label>Font Color</label>\n      <Input @value={{@visual.displayProperties.fontColor}} @type='color' />\n    </div>\n  </div>\n</div>","moduleName":"bi/components/editor/wordcloud-options.hbs","parseOptions":{"srcName":"bi/components/editor/wordcloud-options.hbs"}});
import { set } from '@ember/object';
import Component from '@ember/component';

export default Component.extend({
  didReceiveAttrs() {
    this._super(...arguments);

    let properties = this.visual.displayProperties;

    if (!properties.fontColor || properties.fontColor === '') {
      set(properties, 'fontColor', '#4498B4');
    }
  },
});
