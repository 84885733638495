import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if @isPrintView}}\n  <img src={{this.imgsrc}} width='100%' height='100%' />\n{{else}}\n  <div class='chart' style='width: 100%;height:{{this.height}}; '></div>\n{{/if}}", {"contents":"{{#if @isPrintView}}\n  <img src={{this.imgsrc}} width='100%' height='100%' />\n{{else}}\n  <div class='chart' style='width: 100%;height:{{this.height}}; '></div>\n{{/if}}","moduleName":"bi/components/report/piechart-visual.hbs","parseOptions":{"srcName":"bi/components/report/piechart-visual.hbs"}});
import Component from '@ember/component';
import textformatter from '../../utils/textformatter';
import EchartMixin from '../../mixins/echart-mixin';
import ChartColors from '../../utils/chartcolors';

export default Component.extend(EchartMixin, {
  getOptions() {
    let data = this.data;
    let visual = this.visual;

    let showValAsPct = visual.displayProperties.showValAsPct === 'true';
    let showValues = visual.displayProperties.showValues === 'true';
    let showLegend = visual.displayProperties.showLegend === 'true';
    let valueFormat = visual.displayProperties.valueFormat || '';
    let colorMap = visual.displayProperties.colorMap || null;
    let configuredColorMap = {};
    if (visual.displayProperties.colorMap) {
      configuredColorMap = JSON.parse(colorMap);
    }

    if (!data || !visual) {
      return;
    }

    let cloudData = [];
    let max = 0;

    let index = 0;
    data.results.forEach((row) => {
      if (row[0] != '') {
        row[1] = parseInt(row[1]);

        if (row[1] > max) {
          max = row[1];
        }

        cloudData.pushObject({
          name: row[0],
          value: row[1],
          itemStyle: {
            color: configuredColorMap[row[0]] || ChartColors[index],
          },
        });
      }
      index++;
    });
    let options = {
      tooltip: {
        trigger: 'item',
        formatter: function (params, ticket, callback) {
          return (
            params.data.name +
            '<br/>' +
            params.percent +
            ' %<br/>(' +
            textformatter.format(params.data.value, valueFormat) +
            ')'
          );
        },
      },
      series: [
        {
          type: 'pie',
          radius: ['0%', '60%'],
          avoidLabelOverlap: false,
          itemStyle: {
            borderColor: '#fff',
            borderWidth: 2,
          },
          emphasis: {
            label: {
              show: true,
              fontSize: 20,
              fontWeight: 'bold',
            },
          },
          labelLine: {
            show: false,
          },
          data: cloudData,
        },
      ],
    };
    if (showLegend) {
      options.legend = {
        orient: 'vertical',
        left: 'left',
        top: '30%',
      };
    } else {
      options.legend = {
        show: false,
      };
    }
    if (showValues) {
      options.series[0].label = {
        show: true,
        position: 'outside',
      };
    } else {
      options.series[0].label = {
        show: false,
      };
    }
    // This is the value displayed on the pie chart
    if (showValAsPct) {
      if (showValues) {
        options.series[0].label.formatter = '{b}\n{d}%';
      } else {
        options.series[0].label.formatter = '{d}%';
      }
    } else {
      options.series[0].label.formatter = function (params, ticket, callback) {
        if (showValues) {
          return (
            params.data.name +
            ':\n' +
            textformatter.format(params.data.value, valueFormat)
          );
        } else {
          return textformatter.format(params.data.value, valueFormat);
        }
      };
    }

    return options;
  },
});
