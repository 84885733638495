let options = [
  {
    type: 'IF/THEN/ELSE',
    description: '',
    value: 'IF ... \n\tTHEN ... \n\tELSE ... \nEND',
  },
  {
    type: 'Case',
    description: '',
    value: 'CASE \n\tWHEN ... \n\tTHEN ... \n\tELSE ... \nEND',
  },
  {
    type: 'Concat',
    description: '',
    value: 'concat( , )',
  },
  {
    type: 'Lower Case',
    description: '',
    value: 'lower( )',
  },
  {
    type: 'Upper Case',
    description: '',
    value: 'upper( )',
  },
  {
    type: 'Round',
    description: '',
    value: 'round(<value>, <numdecimals>) ',
  },
  {
    type: 'Cast as Float',
    description: '',
    value: 'cast(<value> as double) ',
  },
  {
    type: 'Substring',
    description:
      'Returns the rest of string from the starting position start. Positions start with 1. A negative starting position is interpreted as being relative to the end of the string.',
    value: 'substr(string, start)',
  },
  {
    type: 'Date Add',
    description: 'Adds or subtract time from a timestamp column',
    value: "date_add('HOUR', -4, ) ",
  },
  {
    type: 'Relative Time Yesterday',
    description: 'Macro for date filter of yesterday',
    value: 'RelativeTime(<field>, YESTERDAY) ',
  },
  {
    type: 'Relative Time Last Week',
    description: 'Macro for date filter of last week',
    value: 'RelativeTime(<field>, LASTWEEK) ',
  },
  {
    type: 'Relative Time This Week',
    description: 'Macro for date filter of this week',
    value: 'RelativeTime(<field>, THISWEEK) ',
  },
  {
    type: 'Relative Time Last Month',
    description: 'Macro for date filter for last month',
    value: 'RelativeTime(<field>, LASTMONTH) ',
  },
  {
    type: 'Relative Time This Month',
    description: 'Macro for date filter for this month',
    value: 'RelativeTime(<field>, THISMONTH) ',
  },
  {
    type: 'Relative Time Previous Number of Days',
    description: 'Macro for date filter for the previous number of days',
    value: 'RelativeTime(<field>, LASTDAYS, 2) ',
  },
  {
    type: 'Timezone Conversion New York',
    description: '',
    value: "<timestamp column> AT TIME ZONE 'America/New_York' ",
  },
  {
    type: 'Timezone Conversion Los Angeles',
    description: '',
    value: "<timestamp column> AT TIME ZONE 'America/Los_Angeles' ",
  },
  {
    type: 'Timezone Conversion Chicago',
    description: '',
    value: "<timestamp column> AT TIME ZONE 'America/Chicago' ",
  },
  {
    type: 'Timezone Conversion Denver',
    description: '',
    value: "<timestamp column> AT TIME ZONE 'America/Denver' ",
  },
];

export default options;
