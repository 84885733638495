import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div\n  class='{{if @column.column.isNumber \"right-align-text\"}}\n    {{unless @isHeader \"monospaced\"}}'\n  style={{this.backgroundColorStyle}}\n>\n  {{#if @value}}\n    {{#if this.isImage}}\n      <img src='{{this.value}}' height='24' width='24' />\n    {{else if this.isLink}}\n      <a href='{{this.value}}' target='_blank'><span class='external-link'>Link\n        </span>\n        <span class='icon-open-in-new'></span></a>\n    {{else if this.isColor}}\n      <div style={{this.backgroundColorTextStyle}}>X</div>\n    {{else if this.isColorWithText}}\n      <div style={{this.textColorStyle}}>{{this.displayValue}}</div>\n    {{else}}\n      {{this.displayValue}}\n    {{/if}}\n  {{else}}\n    {{this.displayValue}}\n  {{/if}}\n</div>", {"contents":"<div\n  class='{{if @column.column.isNumber \"right-align-text\"}}\n    {{unless @isHeader \"monospaced\"}}'\n  style={{this.backgroundColorStyle}}\n>\n  {{#if @value}}\n    {{#if this.isImage}}\n      <img src='{{this.value}}' height='24' width='24' />\n    {{else if this.isLink}}\n      <a href='{{this.value}}' target='_blank'><span class='external-link'>Link\n        </span>\n        <span class='icon-open-in-new'></span></a>\n    {{else if this.isColor}}\n      <div style={{this.backgroundColorTextStyle}}>X</div>\n    {{else if this.isColorWithText}}\n      <div style={{this.textColorStyle}}>{{this.displayValue}}</div>\n    {{else}}\n      {{this.displayValue}}\n    {{/if}}\n  {{else}}\n    {{this.displayValue}}\n  {{/if}}\n</div>","moduleName":"bi/components/report/table-cell.hbs","parseOptions":{"srcName":"bi/components/report/table-cell.hbs"}});
import { htmlSafe } from '@ember/template';
import { observer, computed } from '@ember/object';
import Component from '@ember/component';

import { inject as service } from '@ember/service';
import { later, cancel } from '@ember/runloop';

import textformatter from '../../utils/textformatter';

const linkRegexp = new RegExp('^https?://S*', 'gi');
const imageRegexp = /^https:\/\/\S*.(png|svg|jpg|bmp)/gim;
const colorRegexp = /^#[0-9a-fA-F]{6}$/gim;
const colorWithTextRegexp = /^(#[0-9a-fA-F]{6})\s+(.*)$/gim;

export default Component.extend({
  isLink: false,
  isImage: false,
  isColor: false,
  backgroundColor: 'transparent',
  activetabService: service(),
  textColorStyle: computed('textColor', function () {
    return htmlSafe('color: ' + this.textColor);
  }),
  backgroundColorStyle: computed('backgroundColor', function () {
    return htmlSafe('background-color: ' + this.backgroundColor);
  }),

  backgroundColorTextStyle: computed('backgroundColor', function () {
    return htmlSafe('color: ' + this.backgroundColor);
  }),
  didReceiveAttrs() {
    this._super(...arguments);

    this.set('isLink', false);
    this.set('isImage', false);
    this.set('isColor', false);
    this.set('isColorWithText', false);

    let value = this.value;
    let columnDetails = this.column;

    this.set('displayValue', '');
    this.set('textColor', '');

    if (typeof value === 'undefined' || value === null || value === '') {
      this.set('displayValue', '');
      return;
    }

    if (this.isHeader === true) {
      this.set('displayValue', value);
      return;
    }

    this.set(
      'displayValue',
      textformatter.format(value, columnDetails.valueFormat)
    );

    if (value && value.trim && value.trim() !== '') {
      this.set('isLink', value.match(linkRegexp));
      this.set('isImage', value.match(imageRegexp));
      this.set('isColor', value.match(colorRegexp));
      this.set('isColorWithText', value.match(colorWithTextRegexp));
    }

    if (this.isColorWithText) {
      this.set('textColor', '');
      for (const match of value.matchAll(colorWithTextRegexp)) {
        this.set('textColor', match[1]);
        this.set(
          'displayValue',
          textformatter.format(match[2], columnDetails.valueFormat)
        );
      }
    } else if (this.isColor) {
      this.set('backgroundColor', this.value);
    }

    if (columnDetails.valueFormat == 'time_since') {
      if (!this.runLaterTimer) {
        this.incrementCounter();
      }
    }
  },
  incrementCounter() {
    if (this.isDestroyed || this.activetabService.isHidden) {
      return;
    }

    let displayValue = textformatter.format(this.value, 'time_since');
    this.set('displayValue', displayValue);

    this.runLaterTimer = later(() => {
      this.incrementCounter();
    }, 500);
  },
  isHiddenWatcher: observer('activetabService.isHidden', function () {
    if (this.activetabService.isHidden) {
      if (this.runLaterTimer) {
        cancel(this.runLaterTimer);
      }
    } else {
      cancel(this.runLaterTimer);
      this.incrementCounter();
    }
  }),
});
