import { helper as buildHelper } from '@ember/component/helper';

export function getArrayElement([arr, i]) {
  if (!Array.isArray(arr) || typeof i === 'undefined') {
    return null;
  }
  return arr[i];
}

export default buildHelper(getArrayElement);
