import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.isPrintView}}\n  <img src={{this.imgsrc}} width='100%' height='100%' />\n{{else}}\n  <div class='chart' style={{this.chartStyle}}></div>\n{{/if}}", {"contents":"{{#if this.isPrintView}}\n  <img src={{this.imgsrc}} width='100%' height='100%' />\n{{else}}\n  <div class='chart' style={{this.chartStyle}}></div>\n{{/if}}","moduleName":"bi/components/report/wordcloud-visual.hbs","parseOptions":{"srcName":"bi/components/report/wordcloud-visual.hbs"}});
import { htmlSafe } from '@ember/template';

import { computed } from '@ember/object';
import Component from '@ember/component';

import EchartMixin from '../../mixins/echart-mixin';

export default Component.extend(EchartMixin, {
  chartStyle: computed('height', function () {
    return htmlSafe('width: 100%;height:' + this.height + ';');
  }),
  hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  },
  getOptions() {
    let colorRgb = {
      r: 68,
      g: 152,
      b: 180,
    };
    try {
      let parsedColorRgb = this.hexToRgb(visual.displayProperties.fontColor);
      if (parsedColorRgb) {
        colorRgb = parsedColorRgb;
      }
    } catch (ex) {
      //noop
    }

    let data = this.data;
    let visual = this.visual;

    if (!data || !visual) {
      return;
    }

    let cloudData = [];
    let max = 0;

    data.results.forEach((row) => {
      if (row[0] != '') {
        row[1] = parseInt(row[1]);

        if (row[1] > max) {
          max = row[1];
        }

        cloudData.pushObject({
          name: row[0],
          value: row[1],
          textStyle: {
            color: 'black',
          },
          // emphasis: {
          //     textStyle: {
          //         color: 'red'
          //     }
          // }
        });
      }
    });

    cloudData.forEach((row) => {
      let weight = row.value / max;
      let alpha = weight / 2 + 0.5;
      let color = `rgba(${colorRgb.r},${colorRgb.g},${colorRgb.b},${alpha})`;
      row.textStyle.color = color;
    });

    let options = {
      tooltip: {},
      series: [
        {
          type: 'wordCloud',
          gridSize: 8,
          sizeRange: [12, 100],
          rotationRange: [0, 0],
          width: '100%',
          height: '100%',

          drawOutOfBound: false,

          emphasis: {
            textStyle: {
              shadowBlur: 10,
              shadowColor: '#333',
            },
          },
          data: cloudData,
        },
      ],
    };

    return options;
  },
});
