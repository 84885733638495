import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<table class='table table-striped table-sm'>\n  <thead>\n    <th colspan='2'>Id</th>\n    <th>Folder</th>\n    <th>Name</th>\n    <th>Tables</th>\n  </thead>\n  <tbody>\n    {{#each this.reports as |report|}}\n      <tr>\n        <td colspan='2'>{{report.id}}</td>\n        <td>{{report.folder}}</td>\n        <td>{{report.name}}</td>\n\n        <td>\n          {{#each report.dataSources as |ds|}}\n            {{ds.table}}\n            {{#each ds.relations as |rel|}}\n              ,\n              {{rel.table}}\n            {{/each}}\n          {{/each}}\n\n        </td>\n      </tr>\n    {{/each}}\n  </tbody>\n</table>", {"contents":"<table class='table table-striped table-sm'>\n  <thead>\n    <th colspan='2'>Id</th>\n    <th>Folder</th>\n    <th>Name</th>\n    <th>Tables</th>\n  </thead>\n  <tbody>\n    {{#each this.reports as |report|}}\n      <tr>\n        <td colspan='2'>{{report.id}}</td>\n        <td>{{report.folder}}</td>\n        <td>{{report.name}}</td>\n\n        <td>\n          {{#each report.dataSources as |ds|}}\n            {{ds.table}}\n            {{#each ds.relations as |rel|}}\n              ,\n              {{rel.table}}\n            {{/each}}\n          {{/each}}\n\n        </td>\n      </tr>\n    {{/each}}\n  </tbody>\n</table>","moduleName":"bi/components/report-summary.hbs","parseOptions":{"srcName":"bi/components/report-summary.hbs"}});
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { observer, computed, set } from '@ember/object';

export default Component.extend({
  reportService: service(),
  reports: [],
  didReceiveAttrs() {
    this._super(...arguments);
    this.reports.clear();
    if (this.reportService.myReports) {
      this.reports.pushObjects(
        this.reportService.myReports.sortBy('folder', 'name')
      );
    }
  },
  valuesChanged: observer('reportService.myReports.@each', function () {
    this.reports.clear();
    this.reports.pushObjects(
      this.reportService.myReports.sortBy('folder', 'name')
    );
  }),
});
