export default [
  {
    key: 'table',
    text: 'Table',
    image: 'images/visualicons/table.svg',
    requiredFields: {
      groups: '*',
      values: '*',
    },
  },
  {
    key: 'pivottable',
    text: 'Pivot Table',
    image: 'images/visualicons/pivottable.svg',
    requiredFields: {
      groups: '*',
      values: '*',
    },
  },
  {
    key: 'scalar',
    text: 'Number',
    image: 'images/visualicons/scalar.svg',
    requiredFields: {
      groups: 0,
      values: 1,
    },
    allowAlerts: true,
  },
  {
    key: 'barchart',
    text: 'Bar Chart',
    image: 'images/visualicons/chart-bar.svg',
    requiredFields: {
      groups: '*',
      values: '*',
    },
    allowAlerts: true,
  },
  {
    key: 'stackedbarchart',
    text: 'Stacked Bar Chart',
    image: 'images/visualicons/stacked-chart-bar.svg',
    requiredFields: {
      groups: 2,
      values: 1,
    },
  },
  {
    key: 'piechart',
    text: 'Pie Chart',
    image: 'images/visualicons/pie-chart.svg',
    requiredFields: {
      groups: 1,
      values: 1,
    },
  },
  {
    key: 'horizontalbarchart',
    text: 'Horizantal Bar Chart',
    image: 'images/visualicons/horizontal-chart-bar.svg',
    requiredFields: {
      groups: '*',
      values: '*',
    },
  },
  {
    key: 'horizontalstackedbarchart',
    text: 'Horizantal Stacked Bar Chart',
    image: 'images/visualicons/horizontal-stacked-chart-bar.svg',
    requiredFields: {
      groups: 2,
      values: 1,
    },
  },
  {
    key: 'linegraph',
    text: 'Line Graph',
    image: 'images/visualicons/line-chart.svg',
    requiredFields: {
      groups: '*',
      values: '*',
    },
    allowAlerts: true,
  },
  {
    key: 'areachart',
    text: 'Area Chart',
    image: 'images/visualicons/area-chart.svg',
    requiredFields: {
      groups: '*',
      values: '*',
    },
    allowAlerts: true,
  },

  {
    key: 'stackedareachart',
    text: 'Stacked Area Chart',
    image: 'images/visualicons/area-chart.svg',
    requiredFields: {
      groups: '*',
      values: '*',
    },
    allowAlerts: true,
  },
  {
    key: 'wordcloud',
    text: 'Word Cloud',
    image: 'images/visualicons/wordcloud.svg',
    requiredFields: {
      groups: 1,
      values: 1,
    },
  },
  {
    key: 'gauge',
    text: 'Gauge',
    image: 'images/visualicons/gauge.svg',
    requiredFields: {
      groups: 0,
      values: 1,
    },
    allowAlerts: true,
  },
  {
    key: 'info',
    text: 'Static Info',
    image: 'images/visualicons/staticinfo.svg',
    requiredFields: {
      groups: '*',
      values: '*',
    },
    allowAlerts: false,
  },
  // {
  //     key: "treemap",
  //     text: "Treemap",
  //     image: "images/visualicons/treemap.svg",
  //     requiredFields: {
  //         groups: "*",
  //         values: 1
  //     }
  // },
];
