import FormatterFunctions from './formatterfunctions';

const TIME_REGEX = /^\d{4}\-\d{2}\-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{1,3})?Z?$/g;
const TIME_REGEX_WITH_OPTIONAL_T = /\d{4}\-\d{2}\-\d{2}[T ]\d{2}:\d{2}:\d{2}/g;
const TIME_WITH_OFFSET_REGEX = /\d{4}\-\d{2}\-\d{2} \d{2}:\d{2}:\d{2} /g;

const TIME_DAY_START_REGEX = /\d{4}\-\d{2}\-\d{2}T00:00:00[^\.]/g;
const FIELD_REGEX = /[A-Za-z]{3,}/g;

const TIME_TO_HMS_REGEX = /[0-9]+:[0-9]+:[0-9]+/g;
const TIME_TO_HM_REGEX = /[0-9]+:[0-9]+/g;
const TIME_TO_M_REGEX = /[0-9]+\.[0-9]+/g;
const TIME_TO_MS_REGEX = /[0-9]+:[0-9]+/g;
const TIME_TO_S_REGEX = /[0-9]+\.[0-9]+/g;

// const TITLE_CASE_REGEX = /\w*[\S[^\(]]*/g;// /\w\S*/g;
const TITLE_CASE_REGEX = /\s\w/g; // /\w\S*/g;

const TIME_WITH_ZONE_REGEX =
  /(\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}[.\d]*) [A-Za-z\/_]*/gm;

const GUID_REGEX = /\w{8}\-\w{4}-\w{4}-\w{4}-\w{12}/;

function toTitleCase(str) {
  if (str.toUpperCase() === str) {
    //if string is all uppercase, leave it as all uppercase
    return str;
  }
  str = str.replace('RFactor', ' RFactor');
  str = str.replace('Count(Distinct', 'Count Distinct(');
  str = str.replace('COUNT', 'Count');
  str = str.replace('SUM', 'Sum');
  str = str.replace('AVERAGE', 'Average');
  str = str.replace('AVG', 'Average');
  str = str.replace('MIN', 'Minimum');
  str = str.replace('MAX', 'Maximum');
  // str = str.replace( /([A-Z][a-z])/g, " $1" );
  str = str.replace(/_/g, ' ');
  // str= str.replace(TITLE_CASE_REGEX, function(txt){return " " +  txt.charAt(1).toUpperCase();});
  str = str.charAt(0).toUpperCase() + str.substr(1);
  return str;
}

function isInt(n) {
  return n % 1 === 0;
}

function numberWithCommas(x, specificFormat) {
  var parts = x.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  if (specificFormat === 'commas') {
    return parts[0];
  } else {
    return parts.join('.');
  }
}

function msToHMS(ms) {
  // 1- Convert to seconds:
  var seconds = Math.round(ms / 1000);

  //crazy, seconds < 0 is false for 0.004
  if (seconds * 10 < 10) {
    return '00:00:00';
  }

  // 2- Extract hours:
  var hours = parseInt(seconds / 3600); // 3,600 seconds in 1 hour

  if (isNaN(hours)) {
    return '';
  }

  seconds = seconds % 3600; // seconds remaining after extracting hours
  // 3- Extract minutes:
  var minutes = parseInt(seconds / 60); // 60 seconds in 1 minute
  // 4- Keep only seconds not extracted to minutes:
  seconds = seconds % 60;

  if (minutes < 10) {
    minutes = '0' + minutes;
  }

  seconds = seconds.toFixed(0);
  if (seconds < 10) {
    seconds = '0' + seconds;
  }

  return `${hours}:${minutes}:${seconds}`;
}

function msToDynamicHMS(ms) {
  // 1- Convert to seconds:
  var seconds = Math.round(ms / 1000);

  //crazy, seconds < 0 is false for 0.004
  if (seconds * 10 < 10) {
    return '00:00:00';
  }

  // 2- Extract hours:
  var hours = parseInt(seconds / 3600); // 3,600 seconds in 1 hour

  seconds = seconds % 3600; // seconds remaining after extracting hours
  // 3- Extract minutes:
  var minutes = parseInt(seconds / 60); // 60 seconds in 1 minute
  // 4- Keep only seconds not extracted to minutes:
  seconds = seconds % 60;

  if (minutes < 10) {
    minutes = '0' + minutes;
  }

  seconds = seconds.toFixed(0);
  if (seconds < 10) {
    seconds = '0' + seconds;
  }

  if (isNaN(hours)) {
    return `${minutes}:${seconds}`;
  }
  return `${hours}:${minutes}:${seconds}`;
}

function msToHM(ms) {
  // 1- Convert to seconds:
  var seconds = Math.round(ms / 1000);

  //crazy, seconds < 0 is false for 0.004
  if (seconds * 10 < 10) {
    return '00:00';
  }

  // 2- Extract hours:
  var hours = parseInt(seconds / 3600); // 3,600 seconds in 1 hour

  if (isNaN(hours)) {
    return '';
  }

  seconds = seconds % 3600; // seconds remaining after extracting hours
  // 3- Extract minutes:
  var minutes = parseInt(seconds / 60); // 60 seconds in 1 minute

  if (minutes < 10) {
    minutes = '0' + minutes;
  }

  return `${hours}:${minutes}`;
}

function msToMS(ms) {
  // 1- Convert to seconds:
  var seconds = Math.round(ms / 1000);

  //crazy, seconds < 0 is false for 0.004
  if (seconds * 10 < 10) {
    return '00:00:00';
  }

  // 3- Extract minutes:
  var minutes = parseInt(seconds / 60); // 60 seconds in 1 minute
  // 4- Keep only seconds not extracted to minutes:
  seconds = seconds % 60;

  if (minutes < 10) {
    minutes = '0' + minutes;
  }

  seconds = seconds.toFixed(0);
  if (seconds < 10) {
    seconds = '0' + seconds;
  }

  return `${minutes}:${seconds}`;
}

function msToMinute(ms) {
  // 1- Convert to seconds:
  var seconds = ms / 1000;
  var minutes = seconds / 60;

  minutes = minutes.toFixed(2);

  return `${minutes}`;
}

function msToSeconds(ms) {
  // 1- Convert to seconds:
  var seconds = ms / 1000;

  seconds = seconds.toFixed(2);

  return `${seconds}`;
}

function toPercent(value, decimalPlaces) {
  return (parseFloat(value) * 100).toFixed(decimalPlaces) + ' %';
}

function round(value, decimalPlaces) {
  return parseFloat(value).toFixed(decimalPlaces);
}

export default {
  prettyPrintTime(time) {
    if (time.match(TIME_WITH_OFFSET_REGEX)) {
    }
    return moment.parseZone(time).format('dddd, MMMM Do YYYY'); //, h:mm:ss a z");
  },
  toTitleCase: toTitleCase,
  format(value, specificFormat) {
    if (value == null || value === '') {
      return value;
    }

    if (value.replace) {
      value = value.replace(TIME_WITH_ZONE_REGEX, `$1`);
    }

    if (specificFormat) {
      if (value.match && value.match(TIME_REGEX_WITH_OPTIONAL_T)) {
        FormatterFunctions.forEach((f) => {
          if (specificFormat === 'time_since') {
            let difference = moment().valueOf() - moment(value).valueOf();

            if (isNaN(difference)) {
              return;
            }

            value = msToDynamicHMS(difference);
          } else if (f.moment && f.key == specificFormat) {
            value = moment(value).format(f.moment);
          }
        });
      } else if (specificFormat === 'time_to_hour_min_sec') {
        if (typeof value == 'string') {
          if (!value.match(TIME_TO_HMS_REGEX)) {
            value = msToHMS(value);
          }
        } else {
          value = msToHMS(value);
        }
      } else if (specificFormat === 'time_to_hour_min') {
        if (typeof value == 'string') {
          if (!value.match(TIME_TO_HM_REGEX)) {
            value = msToHM(value);
          }
        } else {
          value = msToHM(value);
        }
      } else if (specificFormat === 'time_to_minute') {
        if (typeof value == 'string') {
          if (!value.match(TIME_TO_M_REGEX)) {
            value = msToMinute(value);
          }
        } else {
          value = msToMinute(value);
        }
      } else if (specificFormat === 'time_to_minute_second') {
        if (typeof value == 'string') {
          if (!value.match(TIME_TO_MS_REGEX)) {
            value = msToMS(value);
          }
        } else {
          value = msToMS(value);
        }
      } else if (specificFormat === 'time_to_second') {
        if (typeof value == 'string') {
          if (!value.match(TIME_TO_S_REGEX)) {
            value = msToSeconds(value);
          }
        } else {
          value = msToSeconds(value);
        }
      } else if (specificFormat === 'percent_2_decimals') {
        if (typeof value == 'string') {
          if (!value.endsWith('%')) {
            value = toPercent(value, 2);
          }
        } else {
          value = toPercent(value, 2);
        }
      } else if (specificFormat === 'percent_1_decimals') {
        if (typeof value == 'string') {
          if (!value.endsWith('%')) {
            value = toPercent(value, 1);
          }
        } else {
          value = toPercent(value, 1);
        }
      } else if (specificFormat === 'percent_0_decimals') {
        if (typeof value == 'string') {
          if (!value.endsWith('%')) {
            value = toPercent(value, 0);
          }
        } else {
          value = toPercent(value, 0);
        }
      } else if (specificFormat === 'round_2_decimals') {
        value = round(value, 2);
      } else if (specificFormat === 'round_1_decimals') {
        value = round(value, 1);
      } else if (specificFormat === 'round_0_decimals') {
        value = round(value, 0);
      } else if (specificFormat === 'dow') {
        value = moment(value).format('dddd');
      } else if (specificFormat === 'dom') {
        value = moment(value).date();
      } else if (specificFormat === 'yyyymmdd') {
        value = moment(value).format('YYYY/MM/DD');
      } else if (specificFormat === 'yyyymmddhhmmss') {
        value = moment(value).format('YYYY/MM/DD HH:mm:ss');
      } else if (specificFormat === 'yyyymmddhhmmss2') {
        value = moment(value).format('YYYY-MM-DD HH:mm:ss');
      } else if (specificFormat === 'commas') {
        value = numberWithCommas(value, specificFormat);
      } else if (specificFormat === 'commas_with_decimal') {
        value = numberWithCommas(value, specificFormat);
      }
    } else {
      if (typeof value === 'string') {
        if (value.match(GUID_REGEX)) {
          return value;
        } else if (value.match(TIME_DAY_START_REGEX)) {
          value = moment.parseZone(value).local().format('L');
        } else if (value.match(TIME_REGEX)) {
          value = moment.parseZone(value).local().format('L  LTS');
        } else if (value.match(FIELD_REGEX)) {
          value = toTitleCase(value);
        } else if (value === 'NULL') {
          value = 'N/A';
        } else if (!isNaN(value) && value[0] != '0') {
          value = Number(value);
        } else if (value.indexOf('tel:') > -1 || value.indexOf('sip:') > -1) {
          value = value.replace('tel:', '').replace('sip:', '');
          value = value.split('@')[0];
        }
      }

      //not else if because the conversion to number happens above
      if (typeof value === 'number') {
        if (!isInt(value)) {
          value = value.toFixed(2);
        }
      }

      if (
        (typeof value === 'number' && isNaN(value)) ||
        (typeof value === 'string' && value === 'NaN')
      ) {
        value = '-';
      }
    }
    return value;
  },
};
