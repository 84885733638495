let timeranges = [
  {
    name: 'Yesterday',
    key: 'YESTERDAY',
  },
  {
    name: 'This Week',
    key: 'THISWEEK',
  },
  {
    name: 'Last Week',
    key: 'LASTWEEK',
  },
  {
    name: 'This Month',
    key: 'THISMONTH',
  },
  {
    name: 'Last Month',
    key: 'LASTMONTH',
  },
  {
    name: 'Previous _ Days',
    key: 'LASTXDAYS',
  },
];

export default timeranges;
