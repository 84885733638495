import Service, { inject as service } from '@ember/service';

const MaxHistoryLength = 14;

export default Service.extend({
  storageService: service(),

  reportView(id, name) {
    let history = this.getHistory();

    for (var x = 0; x < history.length; x++) {
      if (history[x].id == id) {
        history.removeAt(x);
        break;
      }
    }

    while (history.length > MaxHistoryLength) {
      history.removeAt(history.length - 1);
    }

    history.insertAt(0, {
      id,
      name,
    });

    this.storageService.localStorageSet('ReportHistory', history);
  },
  getHistory() {
    return this.storageService.localStorageGet('ReportHistory') || [];
  },
});
