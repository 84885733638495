import Application from '@ember/application';
import Resolver from 'ember-resolver';
import loadInitializers from 'ember-load-initializers';
import config from 'bi/config/environment';

export default class App extends Application {
  modulePrefix = config.modulePrefix;
  podModulePrefix = config.podModulePrefix;
  Resolver = Resolver;
}

loadInitializers(App, config.modulePrefix);

$(window).on('keydown', function (evt) {
  //prevent backwards navigation when backspace is hit
  if (
    evt.keyCode === 8 &&
    evt.target.nodeName.toLowerCase().indexOf('text') === -1 &&
    evt.target.nodeName.toLowerCase().indexOf('input') === -1
  ) {
    evt.preventDefault();
  }
});
