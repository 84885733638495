import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<p class='text-danger'>{{this.message}}</p>\n\n{{#if @context}}\n  <p class='text-muted'>Context: {{@context}}</p>\n{{/if}}", {"contents":"<p class='text-danger'>{{this.message}}</p>\n\n{{#if @context}}\n  <p class='text-muted'>Context: {{@context}}</p>\n{{/if}}","moduleName":"bi/components/error-text.hbs","parseOptions":{"srcName":"bi/components/error-text.hbs"}});
import Component from '@ember/component';

export default Component.extend({
  didReceiveAttrs() {
    this._super(...arguments);
    let error = this.error;

    if (typeof error === 'string') {
      this.set('message', error);
      return;
    } else if (typeof error === 'object') {
      try {
        if (error.responseText) {
          error = JSON.parse(error.responseText);
        }
      } catch (ex) {
        error = error.responseText;
      }

      if (error.message) {
        this.set('message', error.message);
        return;
      }
    }

    this.set('message', JSON.stringify(error));
  },
});
