import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class='container-full'>\n  <div class='row'>\n    <div class='col form-inline'>\n      <label>Min</label>\n      <Input\n        class='form-control'\n        @value={{@visual.displayProperties.min}}\n        @type='number'\n      />\n    </div>\n  </div>\n  <div class='row'>\n    <div class='col form-inline'>\n      <label>Max</label>\n      <Input\n        class='form-control'\n        @value={{@visual.displayProperties.max}}\n        @type='number'\n      />\n    </div>\n  </div>\n</div>", {"contents":"<div class='container-full'>\n  <div class='row'>\n    <div class='col form-inline'>\n      <label>Min</label>\n      <Input\n        class='form-control'\n        @value={{@visual.displayProperties.min}}\n        @type='number'\n      />\n    </div>\n  </div>\n  <div class='row'>\n    <div class='col form-inline'>\n      <label>Max</label>\n      <Input\n        class='form-control'\n        @value={{@visual.displayProperties.max}}\n        @type='number'\n      />\n    </div>\n  </div>\n</div>","moduleName":"bi/components/editor/gauge-options.hbs","parseOptions":{"srcName":"bi/components/editor/gauge-options.hbs"}});
import { set } from '@ember/object';
import Component from '@ember/component';

export default Component.extend({
  didReceiveAttrs() {
    this._super(...arguments);

    let properties = this.visual.displayProperties;

    if (!properties.min || properties.min === '') {
      set(properties, 'min', 0);
    }

    if (!properties.max || properties.max === '') {
      set(properties, 'max', 100);
    }
  },
});
