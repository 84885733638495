import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<label>Release Notes</label>\n\n<form class='form-inline'>\n  {{#if this.isSubscribed}}\n    <p>\n      {{this.configService.me.email}}\n      is currently subscribed to email updates.\n    </p>\n  {{/if}}\n\n  <div class='form-group'>\n    <Input\n      class='form-control mb-3'\n      placeholder='Email Address'\n      @value={{this.subscribeEmail}}\n      @type='email'\n    />\n    <button\n      type='submit'\n      class='btn btn-primary btn-sm mb-3'\n      {{action 'releaseNotesSubscribe'}}\n    >Subscribe</button>\n\n  </div>\n\n</form>\n<button\n  type='button'\n  class='btn btn-sm btn-link rn-badge'\n  {{action 'showReleaseNotes'}}\n>View Release Notes</button>", {"contents":"<label>Release Notes</label>\n\n<form class='form-inline'>\n  {{#if this.isSubscribed}}\n    <p>\n      {{this.configService.me.email}}\n      is currently subscribed to email updates.\n    </p>\n  {{/if}}\n\n  <div class='form-group'>\n    <Input\n      class='form-control mb-3'\n      placeholder='Email Address'\n      @value={{this.subscribeEmail}}\n      @type='email'\n    />\n    <button\n      type='submit'\n      class='btn btn-primary btn-sm mb-3'\n      {{action 'releaseNotesSubscribe'}}\n    >Subscribe</button>\n\n  </div>\n\n</form>\n<button\n  type='button'\n  class='btn btn-sm btn-link rn-badge'\n  {{action 'showReleaseNotes'}}\n>View Release Notes</button>","moduleName":"bi/components/release-notes.hbs","parseOptions":{"srcName":"bi/components/release-notes.hbs"}});
import Component from '@ember/component';

import { inject as service } from '@ember/service';

export default Component.extend({
  requestService: service(),
  notificationService: service(),
  configService: service(),
  isSubscribed: false,
  init() {
    this._super(...arguments);

    this.requestService
      .getResource(`/releasenotes/issubscribed`)
      .then((resp) => {
        this.set('isSubscribed', true);
      })
      .catch((ex) => {
        this.set('isSubscribed', false);
      });
  },
  actions: {
    showReleaseNotes() {
      rnw('show');
    },
    releaseNotesSubscribe() {
      this.requestService
        .postResource(`/releasenotes/subscribe`, { email: this.subscribeEmail })
        .then((resp) => {
          this.notificationService.success('Successfully subscribed');
          this.subscribeEmail = '';
        })
        .catch((ex) => {
          this.notificationService.error(ex.responseJSON.message);
        });
    },
  },
});
