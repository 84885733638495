import { resolve } from 'rsvp';
import Route from '@ember/routing/route';

export default Route.extend({
  model(params, transition) {
    return new resolve({
      report_id: transition.routeInfos[1].context,
    });
  },
});
